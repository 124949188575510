<template>
  <v-row class="pt-0 pb-4 mt-0">
    <v-col cols="12" :key="index" v-for="(bx_hb, index) in box_hub">
      <v-card class="col-12 rounded-lg custom-card-color">
        <v-row justify="start">
          <v-col cols="12" class="d-flex justify-start align-center">
            <div class="charger-img-area" v-if="bx_hb">
              <img class="charger-status-img" :src="bx_hb.icon ? getIcon(bx_hb.icon) : undefined" />
            </div>
            <div class="text-left text v-card-text" v-if="bx_hb.state">
              {{ bx_hb.text }}<br />
              <span class="charging-state">
                <span class="pb-1">
                  {{ bx_hb.state }}
                </span>
              </span>
            </div>
            <div class="ml-auto pt-3 customBlockButton" style="margin-right: 4px" v-if="(isUser && bx_hb.boxInstallationId) && onlySell()">
              <submit-button class="pb-0" :disabled="isButtonBlocked" :title="buttonText" @callEvent="handleBlockUnblock(bx_hb.boxInstallationId)" />
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import mixins from "vue-typed-mixins";
import { General } from "@/mixins/general";
import SubmitButton from "@/components/dashboard/submit-button.vue";

export default mixins(General).extend({
  components: { SubmitButton },
  name: "ChargingState",
  data() {
    return {
      updateChargingStateStatus: this.$store.state.setButtonState,
      isUser: this.$store.state.isUser,
      isButtonBlocked: this.$store.state.buttonUnblockBlock,
      default: false,
      interval: undefined as number | undefined,
    };
  },
  watch: {
    "$store.state.isUser"(newValue) {
      this.isUser = newValue;
    },
    "$store.state.buttonUnblockBlock"(newValue) {
      this.isButtonBlocked = newValue;
    },
    "$store.state.update_charging_state"() {
      this.updateHubBox();
    },
  },
  computed: {
    box_hub() {
      if (this.$store.state.isUser) {
        return this.box_state;
      } else {
        return this.hub_state;
      }
    },
    buttonText() {
      return this.$store.state.boxStatus === "Blocked" ? "Desbloquear" : "Bloquear";
    },
  },

  mounted() {
    this.updateHubBox();
  },
  methods: {
    onlySell() {
      return this.$store.state.condos_response.selected_condo == 22;
    },
    handleBlockUnblock(boxId: number) {
      this.$store
        .dispatch(
          "block_unblock_box",
          boxId
        )
        .catch((error) => {
          console.error("Error:", error);
        });
    },
    async updateHubBox() {
      if (this.condos_response.selected_role == "user") {
        this.$store.dispatch("getBoxList").then((resp) => this.updateChargingState());

      } else {
        this.$store
          .dispatch("getHubsList", {
            profile_id: localStorage.getItem("selected_profile"),
            profile_role: localStorage.getItem("selected_role"),
          })
          .then((resp) => this.updateChargingState());
      }

      const cancelTimeOut = setTimeout(() => {
        if (this.$store.state.timeoutIdLoop) {
          this.updateHubBox();
        }
      }, 30000);
      this.$store.commit("setTimeoutIdForLoop", cancelTimeOut);
    },
  },
});
</script>

<style lang="scss">
.charger-img-area {
  width: 80px;
  margin-top: 5px;
  margin-left: 4px;
}

.charger-status-img {
  max-width: 25%;
  min-width: 60px;
}

.charging-state {
  letter-spacing: 0px;
  font-weight: 700;
  font-size: 18px;
}

.state-icon {
  font-size: 15px;
  margin-top: -2px;
  margin-right: 2px;
}

.custom-card-color {
  background-color: $charcoal-blue !important;
}

.v-card-text {
  color: white;
  font-size: 15px;
}

.customBlockButton .text-disabled {
  color: white !important
}

.customBlockButton .theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: #B1B1B1 !important;
}
</style>
