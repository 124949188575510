var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-row',[_c('div',{staticClass:"col-12 px-0"},[_c('v-form',{ref:"pessoalform",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-col',{staticClass:"pt-0 text text-5 normal-label",attrs:{"cols":"12"}},[_vm._v(" Nome completo ")]),_c('v-col',{staticClass:"pt-0 pb-0 px-3",attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"filled-input",attrs:{"placeholder":_vm.profiles_dmp.length > 0 ? _vm.profiles_dmp[0].name : '-',"height":"44px","background-color":"#D8D8D8","dense":"","outlined":"","disabled":""}})],1),(
          this.condos_response.selected_role == 'manager' &&
          this.condos_response.selected_profile
        )?_c('div',[_c('v-col',{staticClass:"pt-0 text text-5 normal-label",attrs:{"cols":"12"}},[_vm._v(" Tipo de Representante do Condomínio ")]),(_vm.profiles_dmp[0])?_c('v-col',{staticClass:"pt-0 pb-0 px-3",attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"filled-input",attrs:{"placeholder":_vm.profiles_dmp[0].profile_type != ''
                ? _vm.profiles_dmp[0].profile_type
                : '-',"height":"44px","background-color":"#D8D8D8","dense":"","outlined":"","disabled":""}})],1):_vm._e()],1):_vm._e(),(this.condos_response.selected_role == 'user')?_c('div',[_c('div',[_c('v-col',{staticClass:"pt-0 text text-5 normal-label",attrs:{"cols":"12"}},[_vm._v(" NIF ")]),_c('v-col',{staticClass:"pt-0 pb-0 px-3",attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"filled-input",attrs:{"placeholder":_vm.default_address_user
                  ? _vm.default_address_user.tax_identification_number
                  : '-',"height":"44px","background-color":"#D8D8D8","dense":"","outlined":"","disabled":""}})],1)],1),_c('div',[_c('v-col',{staticClass:"pt-0 text text-5 normal-label",attrs:{"cols":"12"}},[_vm._v(" Morada ")]),_c('v-col',{staticClass:"pt-0 pb-0 px-3",attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"filled-input",attrs:{"placeholder":_vm.default_address_user ? _vm.default_address_user.address : '-',"height":"44px","background-color":"#D8D8D8","dense":"","outlined":"","disabled":""}})],1)],1),_c('v-row',{staticClass:"px-3 pt-3"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0 text text-5 normal-label",attrs:{"cols":"12"}},[_vm._v(" Andar ")]),_c('v-col',{staticClass:"pt-0 pb-0 px-3",attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"filled-input",attrs:{"placeholder":_vm.default_address_user ? _vm.default_address_user.floor : '-',"height":"44px","background-color":"#D8D8D8","dense":"","outlined":"","disabled":""}})],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0 text text-5 normal-label",attrs:{"cols":"12"}},[_vm._v(" Porta ")]),_c('v-col',{staticClass:"pt-0 pb-0 px-3",attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"filled-input",attrs:{"placeholder":_vm.default_address_user ? _vm.default_address_user.door : '-',"height":"44px","background-color":"#D8D8D8","dense":"","outlined":"","disabled":""}})],1)],1)],1)],1),_c('v-row',{staticClass:"px-3 py-0"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0 text text-5 normal-label",attrs:{"cols":"12"}},[_vm._v(" Código postal ")]),_c('v-col',{staticClass:"pt-0 pb-0 px-3",attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"filled-input",attrs:{"placeholder":_vm.default_address_user
                      ? _vm.default_address_user.postal_code
                      : '-',"height":"44px","background-color":"#D8D8D8","dense":"","outlined":"","disabled":""}})],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0 text text-5 normal-label",attrs:{"cols":"12"}},[_vm._v(" Cidade ")]),_c('v-col',{staticClass:"pt-0 pb-0 px-38",attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"filled-input",attrs:{"placeholder":_vm.default_address_user ? _vm.default_address_user.city : '-',"height":"44px","background-color":"#D8D8D8","dense":"","outlined":"","disabled":""}})],1)],1)],1)],1)],1):_vm._e(),_c('v-col',{staticClass:"pt-5 pb-3",attrs:{"cols":"12"}},[_c('form-textbox',{attrs:{"title":'Contacto telefónico',"placeholder":'Contacto telefónico',"profile_key":{
            index: this.condos_response.selected_profile,
            key: 'contact_phone',
          },"title_alone":false,"bold":true,"typeNumber":true}})],1),_c('v-col',{staticClass:"pt-2 pb-3",attrs:{"cols":"12"}},[_c('form-textbox',{attrs:{"title":'Email de contacto',"placeholder":'Email de contacto',"profile_key":{
            index: this.condos_response.selected_profile,
            key: 'contact_email',
          },"title_alone":false,"bold":true,"rules":[_vm.emailRule]}})],1),(this.condos_response.selected_role == 'user')?_c('v-col',{staticClass:"mb-5",attrs:{"cols":"12"}},[_c('label',{staticClass:"switch"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.usePersonalData),expression:"usePersonalData"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.usePersonalData)?_vm._i(_vm.usePersonalData,null)>-1:(_vm.usePersonalData)},on:{"change":function($event){var $$a=_vm.usePersonalData,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.usePersonalData=$$a.concat([$$v]))}else{$$i>-1&&(_vm.usePersonalData=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.usePersonalData=$$c}}}}),_c('span',{staticClass:"slider round"})]),_c('span',{staticClass:"pl-3"},[_vm._v("Usar os detalhes do condomínio para efeitos de faturação")])]):_vm._e(),(
          _vm.usePersonalData &&
          this.condos_response.selected_role == 'user'
        )?_c('div',{staticClass:"test-animation mb-5"},[_c('v-col',{staticClass:"pt-4 pb-4",attrs:{"cols":"12"}},[_c('form-textbox',{attrs:{"title":'Nome completo',"placeholder":'Nome completo',"profile_key":{
              index: this.condos_response.selected_profile,
              key: 'name',
              address_type: 'Billing Address',
              value: 'name',
            },"title_alone":false,"bold":true,"rules":[_vm.requiredRule]}})],1),_c('v-col',{staticClass:"pt-4 pb-4",attrs:{"cols":"12"}},[_c('form-textbox',{attrs:{"title":'NIF',"placeholder":'000000000',"profile_key":{
              index: this.condos_response.selected_profile,
              key: 'tax_identification_number',
              address_type: 'Billing Address',
            },"title_alone":false,"bold":true,"rules":[_vm.requiredRule],"typeNumber":true}})],1),_c('v-col',{staticClass:"pt-4 pb-5",attrs:{"cols":"12"}},[_c('form-textbox',{attrs:{"title":'Morada',"placeholder":'Rua',"profile_key":{
              index: this.condos_response.selected_profile,
              key: 'address',
              address_type: 'Billing Address',
            },"title_alone":false,"bold":true,"rules":[_vm.requiredRule]}})],1),_c('v-col',{staticClass:"pt-4 pb-5",attrs:{"cols":"12"}},[_c('form-textbox',{attrs:{"title":'Fração',"placeholder":'1 DTO',"optional_field":'(Opcional)',"profile_key":{
              index: this.condos_response.selected_profile,
              key: 'fraction',
              address_type: 'Billing Address',
            },"title_alone":false,"bold":true}})],1),_c('v-row',{staticClass:"px-3 py-0"},[_c('v-col',{staticClass:"pt-4 pb-2",attrs:{"cols":"6"}},[_c('form-textbox',{attrs:{"title":'Código Postal',"placeholder":'0000-000',"profile_key":{
                index: this.condos_response.selected_profile,
                key: 'postal_code',
                address_type: 'Billing Address',
              },"title_alone":false,"bold":true,"rules":[_vm.requiredRule]}})],1),_c('v-col',{staticClass:"pt-4 pb-2",attrs:{"cols":"6"}},[_c('form-textbox',{attrs:{"title":'Cidade',"placeholder":'Cidade',"profile_key":{
                index: this.condos_response.selected_profile,
                key: 'city',
                address_type: 'Billing Address',
              },"title_alone":false,"bold":true,"rules":[_vm.requiredRule]}})],1)],1)],1):_vm._e(),(this.condos_response.selected_role != 'user')?_c('v-col',{attrs:{"cols":"12"}},[_c('submit-button',{attrs:{"loading":_vm.loading,"name":'handle-condos-profile',"disabled":_vm.loading,"title":'Guardar',"textSize":true,"height":35},on:{"callEvent":_vm.validate}})],1):_vm._e(),(this.condos_response.selected_role == 'user')?_c('v-col',{attrs:{"cols":"12"}},[_c('submit-button',{attrs:{"textSize":true,"name":'handle-user-profile',"title":'Guardar',"height":35},on:{"callEvent":_vm.validate}})],1):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }