var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-row',{staticClass:"overlay-content",attrs:{"align":"start","justify":"center"}},[_c('v-col',{staticClass:"d-flex justify-center options",on:{"click":_vm.closeModal}},[_c('v-icon',{staticClass:"d-flex mt-3 mr-0",staticStyle:{"padding-left":"280px"},attrs:{"ripple":false},on:{"click":_vm.closeModal}},[_vm._v("mdi-close ")])],1),_c('v-col',{staticClass:"d-flex justify-center options",attrs:{"cols":"12"}},[_c('img',{attrs:{"width":"200","src":require("@/assets/logos/edge-white-logo.svg")}})]),_c('v-col',{staticClass:"d-flex justify-center pb-0",staticStyle:{"font-size":"14px"},attrs:{"cols":"12"}},[_vm._v(" Perfil Atual ")]),_c('v-col',{staticClass:"d-flex pt-0 pb-2 justify-center",staticStyle:{"font-size":"24px"},attrs:{"cols":"12"}},[_vm._v(_vm._s(this.condos_response.selected_role == "user" ? "Morador" : "Administrador"))]),(_vm.checkExistingUser)?_c('v-col',{staticClass:"d-flex align-center justify-center pt-0",attrs:{"cols":"12"}},[_c('button',{staticClass:"btn-change d-flex align-center justify-center",on:{"click":_vm.handleChangeUser}},[_c('img',{staticClass:"pr-1",attrs:{"src":require("@/assets/icons/change-user.svg")}}),_c('span',[_vm._v(_vm._s(this.condos_response.selected_role == "manager" ? "Mudar Para Morador" : "Mudar Para Administrador"))])])]):_vm._e(),(this.profiles.length != 0)?_c('v-col',{staticStyle:{"font-size":"22px"},attrs:{"cols":"12"},on:{"click":function($event){return _vm.handleChangeTab(0)}}},[_c('router-link',{staticClass:"d-flex clickable font-weight-bold justify-center",class:this.$route.path === '/dashboard/consumos' ? 'item-selected' : 'item',attrs:{"to":{ path: '/dashboard/consumos' }}},[_vm._v(" Consumos ")])],1):_vm._e(),(
      this.condos_response &&
      this.profiles.length != 0 &&
      this.condos_response.selected_role == 'manager'
    )?_c('v-col',{staticClass:"d-flex clickable font-weight-bold item justify-center",staticStyle:{"font-size":"22px"},attrs:{"cols":"12"},on:{"click":function($event){return _vm.handleChangeTab(1)}}},[_c('router-link',{staticClass:"d-flex clickable font-weight-bold justify-center",class:this.$route.path === '/dashboard/tariff' ? 'item-selected' : 'item-1',attrs:{"to":{ path: '/dashboard/tariff' }}},[_vm._v(" Faturação ")])],1):_vm._e(),(this.profiles.length != 0)?_c('v-col',{staticClass:"d-flex clickable font-weight-bold item-1 justify-center",staticStyle:{"font-size":"22px"},attrs:{"cols":"12"},on:{"click":_vm.handleSupport}},[_c('router-link',{staticClass:"d-flex clickable font-weight-bold justify-center",class:this.$route.path === '/dashboard/support' ? 'item-selected' : 'item-1',attrs:{"to":{ path: '/dashboard/support' }}},[_vm._v(" Apoio ao Cliente ")])],1):_vm._e(),(this.profiles.length != 0)?_c('v-col',{staticStyle:{"font-size":"22px"},attrs:{"cols":"12"},on:{"click":function($event){return _vm.handleChangeTab(4)}}},[_c('router-link',{staticClass:"d-flex clickable font-weight-bold justify-center",class:this.$route.path === '/dashboard/manage-account'
          ? 'item-selected'
          : 'item-1',attrs:{"to":{ path: '/dashboard/manage-account' }}},[_vm._v(" A minha conta ")])],1):_vm._e(),_c('v-col',{staticClass:"d-flex clickable font-weight-bold item justify-center",staticStyle:{"font-size":"22px"},attrs:{"cols":"12"},on:{"click":_vm.logoutUser}},[_vm._v(" Logout / Sair ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }