var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-row',[_c('div',{staticClass:"col-12 px-0"},[_c('v-form',{ref:"condominioform",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[(this.condos_response.selected_role == 'manager')?_c('div',[_c('div',[_c('v-col',{staticClass:"pt-0 text text-5 normal-label",attrs:{"cols":"12"}},[_vm._v(" Nome do condomínio ")]),_c('v-col',{staticClass:"pt-0 pb-0 px-3",attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"filled-input",attrs:{"placeholder":_vm.profiles_dmp[0].condominium_account != null
                  ? _vm.profiles_dmp[0].condominium_account.name
                  : '-',"height":"44px","background-color":"#D8D8D8","dense":"","outlined":"","disabled":""}})],1)],1),_c('div',[_c('v-col',{staticClass:"pt-0 text text-5 normal-label",attrs:{"cols":"12"}},[_vm._v(" NIF ")]),_c('v-col',{staticClass:"pt-0 pb-0 px-3",attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"filled-input",attrs:{"placeholder":_vm.profiles_dmp[0].condominium_account != null
                  ? _vm.profiles_dmp[0].condominium_account
                      .tax_identification_number
                  : '-',"height":"44px","background-color":"#D8D8D8","dense":"","outlined":"","disabled":""}})],1)],1),_c('div',[_c('v-col',{staticClass:"pt-3 text text-5 normal-label",attrs:{"cols":"12"}},[_vm._v(" Fração ")]),_c('v-col',{staticClass:"pt-0 pb-0 px-3",attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"filled-input",attrs:{"placeholder":_vm.default_address_condo != null
                  ? _vm.default_address_condo.fraction
                  : '-',"height":"44px","background-color":"#D8D8D8","dense":"","outlined":"","disabled":""}})],1)],1),_c('v-row',{staticClass:"px-3 pt-5 py-0"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0 text text-5 normal-label",attrs:{"cols":"12"}},[_vm._v(" Código Postal ")]),_c('v-col',{staticClass:"pt-0 pb-0 px-3",attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"filled-input",attrs:{"placeholder":_vm.default_address_condo != null
                      ? _vm.default_address_condo.postal_code
                      : '-',"height":"44px","background-color":"#D8D8D8","dense":"","outlined":"","disabled":""}})],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0 text text-5 normal-label",attrs:{"cols":"12"}},[_vm._v(" Cidade ")]),_c('v-col',{staticClass:"pt-0 pb-0 px-38",attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"filled-input",attrs:{"placeholder":_vm.default_address_condo != null
                      ? _vm.default_address_condo.city
                      : '-',"height":"44px","background-color":"#D8D8D8","dense":"","outlined":"","disabled":""}})],1)],1)],1)],1)],1):_vm._e(),_c('div',[_c('v-col',{staticClass:"pt-3 text text-5 normal-label",attrs:{"cols":"12"}},[_vm._v(" Morada ")]),_c('v-col',{staticClass:"pt-0 pb-5 px-3",attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"filled-input",attrs:{"placeholder":_vm.default_address_condo != null
                ? _vm.default_address_condo.address
                : '-',"height":"44px","background-color":"#D8D8D8","dense":"","outlined":"","disabled":""}})],1)],1),_c('v-col',{staticClass:"mb-5",attrs:{"cols":"12"}},[_c('label',{staticClass:"switch"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.usePersonalData),expression:"usePersonalData"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.usePersonalData)?_vm._i(_vm.usePersonalData,null)>-1:(_vm.usePersonalData)},on:{"change":function($event){var $$a=_vm.usePersonalData,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.usePersonalData=$$a.concat([$$v]))}else{$$i>-1&&(_vm.usePersonalData=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.usePersonalData=$$c}}}}),_c('span',{staticClass:"slider round"})]),_c('span',{staticClass:"pl-3"},[_vm._v("Usar os detalhes do condomínio para efeitos de faturação")])]),(_vm.usePersonalData)?_c('div',{staticClass:"test-animation mb-5"},[_c('v-col',{staticClass:"pt-4 pb-4",attrs:{"cols":"12"}},[_c('form-textbox',{attrs:{"title":'Nome completo',"placeholder":'Nome completo',"profile_key":{
              index: this.condos_response.selected_profile,
              key: 'name',
              address_type: 'Billing Address',
              account: 'condominium_account',
              value: 'name',
            },"title_alone":false,"bold":true,"rules":[_vm.requiredRule]}})],1),_c('v-col',{staticClass:"pt-4 pb-4",attrs:{"cols":"12"}},[_c('form-textbox',{attrs:{"title":'NIF',"placeholder":'000000000',"profile_key":{
              index: this.condos_response.selected_profile,
              key: 'tax_identification_number',
              address_type: 'Billing Address',
              account: 'condominium_account',
            },"title_alone":false,"bold":true,"rules":[_vm.requiredRule],"typeNumber":true}})],1),_c('v-col',{staticClass:"pt-4 pb-5",attrs:{"cols":"12"}},[_c('form-textbox',{attrs:{"title":'Morada',"placeholder":'Rua',"profile_key":{
              index: this.condos_response.selected_profile,
              key: 'address',
              address_type: 'Billing Address',
              account: 'condominium_account',
            },"title_alone":false,"bold":true,"rules":[_vm.requiredRule]}})],1),_c('v-col',{staticClass:"pt-4 pb-5",attrs:{"cols":"12"}},[_c('form-textbox',{attrs:{"title":'Fração',"placeholder":'1 DTO',"optional_field":'(Opcional)',"profile_key":{
              index: this.condos_response.selected_profile,
              key: 'fraction',
              address_type: 'Billing Address',
              account: 'condominium_account',
            },"title_alone":false,"bold":true,"noValidate":true}})],1),_c('v-row',{staticClass:"px-3 py-0"},[_c('v-col',{staticClass:"pt-4 pb-2",attrs:{"cols":"6"}},[_c('form-textbox',{attrs:{"title":'Código Postal',"placeholder":'0000-000',"profile_key":{
                index: this.condos_response.selected_profile,
                key: 'postal_code',
                address_type: 'Billing Address',
                account: 'condominium_account',
              },"title_alone":false,"bold":true,"rules":[_vm.requiredRule]}})],1),_c('v-col',{staticClass:"pt-4 pb-2",attrs:{"cols":"6"}},[_c('form-textbox',{attrs:{"title":'Cidade',"placeholder":'Cidade',"profile_key":{
                index: this.condos_response.selected_profile,
                key: 'city',
                address_type: 'Billing Address',
                account: 'condominium_account',
              },"title_alone":false,"bold":true,"rules":[_vm.requiredRule]}})],1)],1)],1):_vm._e(),_c('v-col',{staticClass:"mb-10",attrs:{"cols":"12"}},[_c('submit-button',{attrs:{"name":'handle-condos-profile',"loading":_vm.loading,"height":35,"title":'Guardar',"textSize":true},on:{"callEvent":_vm.validate}})],1)],1)],1),_c('success-modal',{attrs:{"modal-name":'handle-condos-profile',"header":'Alterações gravadas!',"text":'As tuas Alterações foram gravadas.',"icon":'Union.svg'}}),_c('success-modal',{attrs:{"modal-name":'handle-user-profile',"icon":'Union.svg',"header":'Alterações gravadas!',"text":'As tuas Alterações foram gravadas.'}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }