<template>
  <v-container class="container-custom" fluid>
    <!-- Dashboard area -->
    <v-row justify="space-around" align="start" class="page-container">
      <v-col class="dashboard-area">
        <!-- Header logo and logout -->

        <div style="min-height:70px" class="container-2 pb-5">
          <div class="item1">
            <img class="dashboard-logo" src="@/assets/logos/edge-logo.svg" />
          </div>
          <div class="item2">
            <div @click="displayModal"
              class="hamburger d-flex ml-auto flex-column justify-center align-center text-right clickable">
              <!-- <img style="fill:white; color:white; background" src="@/assets/icons/hamburger.svg" /> -->
              <div v-if="this.profiles.length != 0">
                <div class="py-0 dash" cols="12">
                  <img width="23" src="@/assets/icons/dashes.svg" />
                </div>
                <div class="py-0 dash" cols="12">
                  <img width="23" src="@/assets/icons/dashes.svg" />
                </div>
                <div class="pb-2" cols="12">
                  <img width="23" src="@/assets/icons/dashes.svg" />
                </div>
              </div>

            </div>
          </div>
        </div>

        <!-- <v-col>
            <div
              class="logout-circle rounded-circle clickable"
              @click="logoutUser()">
              <v-icon color="#ffffff" class="logout-icon">mdi-logout</v-icon>
            </div>
          </v-col> -->

        <div v-if="showCondos == true">
          <condos-page @callEvent="closeCondo" @checkMethod="checkButton" />
        </div>
        <v-navigation-drawer v-model="showModal" absolute temporary retain-focus-on>
          <v-overlay absolute class="bg-menu">
            <menu-page @callEvent="closeMenu" />
          </v-overlay>
        </v-navigation-drawer>

        <!-- End header logo and logout -->

        <!-- Header title -->
        <v-row class="px-3 pb-3 pt-9"
          v-if="(active_tab == 0 || active_tab == 1 || active_tab == 4 || active_tab == 3) && this.condos_response.condos.length != 0">
          <v-col cols="12" class="pt-6">
            <v-row>
              <v-col class="pl-0" cols="6" sm="8"><v-row>
                  <v-col cols="12" style="font-size: 14px"
                    class="d-flex justify-start condo font-weight-bold pt-0 pb-0">Condomínio</v-col>
                  <v-col class="pt-0" cols="12">{{
                    this.condos_response.condos[
                      this.condos_response.condos.findIndex(val => val.id == this.condos_response.selected_condo)
                    ].name
                  }}</v-col>
                </v-row></v-col>
              <v-col cols="6" sm="4" class="text-5 pr-0">
                <v-row>
                  <v-col cols="12" class="py-1 pl-16"><submit-button :edit="true" :height="35" :width="89"
                      :icon="'mdi-domain'" :title="'Escolher'" :textSize="true" @callEvent="displayCondo" /></v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <!-- End header title -->
        <!--  v-if="user.role == 'user'"-->
        <!-- This is hidden for now-->
        <!-- <v-row class="px-3" v-if="active_tab == 0 && this.condos_response.selected_role == 'user'">
          <v-col cols="12" class="px-0"><form-select :type="'Box'" :condo_key="'selected_box'" :field_type="'string'"
              :title="'Seleccione uma Box'" :items="this.boxes
                " /></v-col>
        </v-row> -->

        <!-- Charging state charging -->
        <charging-state
          v-if="(box_state || hub_state && (Object.keys(hub_state).length > 0 || Object.keys(box_state).length > 0)) && this.billing_periods.length != 0 && ($route.path == '/dashboard/consumos' || $route.path == '/dashboard')" />
        <!-- End charging state charging -->

        <!-- Dashboard mode -->
        <!-- <dashboard-mode
          v-if="this.condos_response && this.condos_response.selected_role == 'manager' && active_tab != 3" /> -->
        <!-- End dashboard mode -->
        <!-- Consumo -->
        <!-- <consumo v-if="active_tab == 0" /> -->
        <!-- End consumo -->

        <!-- Tariff -->
        <!-- <tariff :period="0" v-if="active_tab == 1" /> -->
        <!-- End Tariff -->

        <router-view></router-view>

        <!-- <support v-if="active_tab == 3" />
        <manage-account v-if="active_tab == 4" /> -->
      </v-col>
    </v-row>
    <!-- End dashboard area -->
    <!-- Footer -->
    <v-row justify="space-around" class="mt-6 dashboard-footer">
      <!-- Footer text -->
      <v-col class="footer-text-area">
        <footer-text :activeTab="this.active_tab" />
      </v-col>
      <!-- End footer text -->
    </v-row>
    <!-- End Footer -->
    <!-- Snackbar -->
    <snackbar />
    <!-- End snackbar -->
  </v-container>
</template>
<script lang="ts">
import mixins from "vue-typed-mixins";
import { General } from "@/mixins/general";
import Snackbar from "@/components/general/snackbar.vue";
import ChargingState from "@/components/dashboard/charging-state.vue";
import DashboardMode from "@/components/dashboard/dashboard-mode.vue";
import Consumo from "@/views/consumo.vue";
import Report from "@/views/report.vue";
import Tariff from "@/views/tariff.vue";
import FooterText from "@/components/general/footer-text.vue";
import MenuPage from "@/components/dashboard/menu-page.vue";
import Support from "./support.vue";
import SubmitButton from "@/components/dashboard/submit-button.vue";
import CondosPage from "@/components/dashboard/condos-page.vue";
import ManageAccount from "./manage-account.vue";
import FormSelect from "@/components/forms/form-select.vue";
export default mixins(General).extend({
  name: "Dashboard",
  components: {
    Snackbar,
    ChargingState,
    DashboardMode,
    Consumo,
    Tariff,
    FooterText,
    Report,
    MenuPage,
    Support,
    SubmitButton,
    CondosPage,
    ManageAccount,
    FormSelect,
  },
  data() {
    return {
      showCondos: false,
      tab_index: 0,
      showModal: false,
    };
  },

  created() {
    const showConsumptionValuesSnackBar = localStorage.getItem('showConsumptionValuesSnackBar');
    if (showConsumptionValuesSnackBar && showConsumptionValuesSnackBar !== 'false') {
      this.$store.dispatch("activateSnackBar")
    }
    this.initActions();
  },


  computed: {
    selected(): any {
      return this.condos_response.selected_condo
    },
    boxes(): any {

      return this.$store.state.condos_response.condos[
        this.selected - 1
      ].boxes.map((box: any, index: any) => ({
        value: String(box.id),
        text: String(box.info),
      }))
    },
  },
  watch: {
    consumptionDataUpdate(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.initActions();
      }
    }
  },
  methods: {
    checkButton(e: any) {
      return
    },

    logoutUser(): any {
      return this.$store.dispatch("logout");
    },

    displayCondo(): any {
      this.showCondos = true;
    },

    closeCondo(): any {
      this.showCondos = false;
    },

    displayModal(): any {
      this.showModal = true;
    },
    closeMenu(): any {
      this.showModal = false;
    },
    initActions() {

      this.$store.dispatch("requestUser").then(async () => { if (this.profiles.length != 0) await this.$store.dispatch("getBillingPeriods") }).then(() => {
        this.$store.dispatch("requestUser").then(async () => {
            if (this.profiles.length != 0 && this.billing_periods.length != 0) {
              this.getConsumptionData(`${this.billing_periods.length != 0 ? this.billing_periods[this.selected_index].start_date : ''}`, `${this.billing_periods.length != 0 ? this.billing_periods[this.selected_index].end_date : ''}`)
                .then(() => {

                  if (this.selected_index == 0 && this.profiles.length != 0) {
                    this.getConsumptionData(`${this.billing_periods.length != 0 ? this.billing_periods[1].start_date : ''}`, `${this.billing_periods.length != 0 ? this.billing_periods[1].end_date : ''}`, true)
                  }
                  if (this.condos_response && this.profiles.length != 0 && this.condos_response.selected_role != "user") {
                    this.$store.dispatch("getContractData", { uri: "bill_data" });
                  }
                })
            }
          });
        });
    }
  },
});
</script>
<style lang="scss">
.container-custom {
  height: 91% !important;
}

.condo {
  color: $blue-1;
}

/* Apply transition effect for the overlay content */
.v-enter-active,
.v-leave-active {
  transition: transform 0.9s;
}

.v-enter,
.v-leave-to

/* .v-leave-active in <2.1.8 */
  {
  transform: translateX(-100%);
}

.v-navigation-drawer {
  min-width: 100vw !important;
  background-color: rgba(255, 255, 255, 0) !important;
}

.dash {
  height: 5px;
}

.button-test {
  max-width: 110px;
}

.hamburger {
  max-width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #273043;
}

.dashboard-area {
  max-width: 800px;
}

.dashboard-logo {
  width: 30%;
  min-width: 150px;
}

.dashboard-footer {
  background-color: $gray-1;
  padding-top: 36px;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.dashboard-footer-text-area {
  max-width: 800px !important;
}

.logout-icon {
  margin-left: 4px;
  margin-top: -2px;
}

.container-2 {
  position: relative;
  display: grid;
  height: 100%;
  place-items: center;
}

.item1 {
  position: absolute;
  padding-bottom: 16px;
  top: 50%;
  left: 50%;
  display: flex;
  justify-content: center;
  /* Center horizontally */
  transform: translate(-50%, -50%);
  /* Adjust for item1's own dimensions */
}

.item2 {
  width: 50px;
  justify-self: end;
  /* Aligns the item to the right of the grid */
}

.logout-circle {
  margin: auto;
  font-weight: 700;
  text-align: center;
  font-family: "Fira Sans";
  background: $blue-1;
  width: 50px;
  width: 50px;
  line-height: 50px;
  font-size: 22px;
}

.bg-menu {
  background-color: rgba(0, 0, 0, 0.85);
  width: 100vw;
  height: 100%;
  align-items: start;
  justify-content: start;
}

@media screen and (max-width: 320px) {
  .logout-circle {
    font-size: 16px;
    width: 40px !important;
    width: 40px !important;
    line-height: 40px !important;
  }
}
</style>
