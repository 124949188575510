<!-- eslint-disable prettier/prettier -->
<template>
  <v-dialog
    v-model="modalShow"
    max-width="570px"
    content-class="simple-content-modal  overflow-x-hidden rounded-lg external mx-5 mb-6"
  >
    <v-card class="d-flex flex-column align-center">
      <div class="top-nav d-flex bottom-liner py-0 mb-3">
        <p class="select-period my-4 mx-5 d-flex align-center font-weight-bold">
          Editar Período
        </p>

        <v-icon
          class="ml-auto align-center mr-4 close-modal"
          @click="closeModal(modalName)"
          >mdi-close
        </v-icon>
      </div>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row class="px-0 mx-0">
          <v-col
            style="font-size: 16px"
            class="px-5 font-weight-medium px-0 pb-0 normal-label text"
            cols="12"
            >Selecione a data de início e de fim da última fatura de energia do
            condomínio que recebeu</v-col
          >
          <v-col class="pl-0 pb-0 px-0" cols="6"
            ><v-row class="px-0 pb-0 mx-0">
              <v-col class="px-5 pb-2 mx-0" cols="12">Data de Início</v-col>
              <v-col class="pb-3 pt-0 pl-5 mx-0" cols="12"
                ><form-textbox
                  :field_key="'bp_start_date'"
                  :period="0"
                  :field_type="'date'"
                  :placeholder="'dd-mm-yyyy'"
                  :icon="'mdi-calendar-blank'"
                  :bold="true"
                  :title_alone="true"
                  :edit="true"
                  :isDate="true"
              /></v-col> </v-row
          ></v-col>
          <v-col class="pr-0 pb-0 px-0" cols="6"
            ><v-row class="px-0 pb-0 mx-0">
              <v-col class="px-5 pb-2 mx-0" cols="12">Data de Fim</v-col>
              <v-col class="pr-5 pt-0 pb-3 mx-0" cols="12"
                ><form-textbox
                  :field_key="'bp_end_date'"
                  :period="0"
                  :field_type="'date'"
                  :placeholder="'dd-mm-yyyy'"
                  :icon="'mdi-calendar-blank'"
                  :bold="true"
                  :edit="true"
                  :title_alone="true"
                  :isDate="true"
              /></v-col> </v-row
          ></v-col>

          <v-col cols="12" class="pt-0 pb-0 px-5 px-0">
            <tariff-cycle :edit="true" />
          </v-col>

          <v-col cols="12" class="pt-0 px-5 px-0">
            <form-select
              :field_type="'string'"
              :edit="true"
              :field_key="'plan'"
              :title="'Indique o nível de tensão da eletricidade do condomínio'"
              :items="[
                { value: '0', text: 'Baixa Tensão Normal (BTN)' },
                { value: '1', text: 'Baixa Tensão Especial (BTE)' },
                { value: '2', text: 'Media Tensão (MT)' },
                { value: '3', text: 'Alta Tensão (AT)' },
              ]"
              :placeholder="'Selecionar Tensão'"
              @clearFormData="reset"
            />
          </v-col>
          <v-col
            cols="12"
            class="pt-3 pb-1 px-5 px-0"
            v-if="this.edit_data[0].plan == 0"
          >
            <form-radio
              :field_type="'string'"
              :period="0"
              :edit="true"
              :field_key="'schedule_type'"
              :title="'Selecione os períodos horários de entrega de energia elétrica'"
              :items="[
                { value: '0', text: 'Simples' },
                { value: '1', text: 'Bi-Horário' },
                { value: '2', text: 'Tri-Horário' },
              ]"
            />
          </v-col>
          <v-col cols="12" class="pt-4 px-5 px-0">
            <form-textbox
              :field_key="'contracted_power'"
              :period="0"
              :edit="true"
              :required="true"
              :title="'Indique a potência contratada pelo condomínio'"
              :placeholder="'Potência Contratada (ex.: 0.0)'"
              :field_type="'float'"
              :suffix="'kVA'"
              :bold="true"
              :title_alone="false"
            />
          </v-col>

          <!-- Adding option to add multiple tariffs -->
          <v-col cols="12" class="px-0 px-5 text text-5 normal-label"
            >Indique o número de períodos de faturação existentes na
            fatura.</v-col
          >
          <v-col cols="12" class="d-flex px-5 justify-start px-0 pt-0"
            ><button
              type="button"
              :disabled="this.numberTariffs == 1 || deletePeriods == true"
              @click="decrementTariff"
              class="sign d-flex align-center justify-center mr-8"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="3"
                class="minus-sign"
                viewBox="0 0 21 3"
              >
                <path
                  data-name="Minus Sign"
                  transform="translate(1.5 1.5)"
                  style="
                    fill: none;
                    stroke: #fff;
                    stroke-linecap: round;
                    stroke-width: 3px;
                  "
                  d="M18 0H0"
                />
              </svg>
            </button>
            <div class="d-flex align-center">{{ this.numberTariffs }}</div>
            <button
              type="button"
              :disabled="deletePeriods == true"
              @click="incrementTariff"
              class="sign d-flex align-center justify-center ml-8"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                class="plus-sign"
                viewBox="0 0 21 21"
              >
                <g data-name="Plus Sign">
                  <path
                    data-name="Linha 85"
                    transform="translate(10.5 1.5)"
                    style="
                      fill: none;
                      stroke: #fff;
                      stroke-linecap: round;
                      stroke-width: 3px;
                    "
                    d="M0 0v18"
                  />
                  <path
                    data-name="Linha 86"
                    transform="translate(1.5 10.5)"
                    style="
                      fill: none;
                      stroke: #fff;
                      stroke-linecap: round;
                      stroke-width: 3px;
                    "
                    d="M18 0H0"
                  />
                </g>
              </svg>
            </button>
          </v-col>

          <!-- Show Periods -->
          <v-col
            cols="12"
            class="px-0 px-0"
            v-for="(n, index) in this.numberTariffs"
            :key="index"
          >
            <v-row
              class="px-2 py-0 d-flex align-center"
              :key="formErrors[index]"
            >
              <v-col
                v-if="deletePeriods == true"
                @click="removeTariff(n - 1)"
                :class="deletePeriods == true ? 'enter-active' : 'leave-active'"
                class="d-flex delete-sign align-center ml-1"
                cols="1"
                ><img src="@/assets/icons/trash-2.svg"
              /></v-col>
              <v-col
                class="mr-auto d-flex align-center header-3 pl-6 px-0 font-weight-bold"
                >Sub-Período {{ n }}
                <img
                  v-if="formErrors[index]"
                  style="width: 28px"
                  class="pl-2"
                  :src="require('@/assets/icons/warning-121.svg')"
              /></v-col>
              <v-col class="d-flex justify-end pr-5"
                ><v-icon
                  size="40"
                  @click="toggleRotation(n, true)"
                  color="black"
                  :class="isRotated && n == catSelected ? 'rotated' : 'revert'"
                  >mdi-chevron-right
                </v-icon></v-col
              >
            </v-row>
            <div v-show="catSelected == n && isRotated" class="pt-7">
              <v-row
                class="px-5 py-0 pb-2"
                v-show="catSelected == n && isRotated"
              >
                <v-col cols="6"
                  ><form-textbox
                    :field_key="'start_date'"
                    :edit="true"
                    :required="true"
                    :field_type="'date'"
                    :icon="'mdi-calendar-blank'"
                    :bold="true"
                    :title="'Data de Início'"
                    :isDate="true"
                /></v-col>
                <v-col cols="6"
                  ><form-textbox
                    :field_key="'end_date'"
                    :edit="true"
                    :required="true"
                    :field_type="'date'"
                    :icon="'mdi-calendar-blank'"
                    :bold="true"
                    :title="'Data de Fim'"
                    :isDate="true"
                /></v-col>
              </v-row>
              <v-row class="px-8 pt-0">
                <v-col class="px-0 py-0">
                  <fill-tariffs
                    :edit="true"
                    :index="index"
                    :checkMargins="true"
                    ref="fillTariffsEdit"
                    :noMargins="true"
                    :checkSubmitButton="checkSubmit"
                  />
                </v-col>
              </v-row>
            </div>
          </v-col>

          <v-col cols="12" class="pt-4 px-5 px-0 pb-0">
            <submit-button
              :inverted="false"
              :title="'Confirmar Edição de Período'"
              @callEvent="validate"
              :name="'edit-tariff-info'"
            />
          </v-col>
          <v-col cols="12" class="pt-0 px-5 px-0 pb-4">
            <submit-button
              :inverted="true"
              @callEvent="closeModal(modalName)"
              :title="'Cancelar'"
              :name="'edit-tariff-info'"
            />
          </v-col>
        </v-row>
      </v-form>
      <success-modal
        :modalName="'edit-tariff-info'"
        :alt="'tariff success'"
        :icon="'icon-new-tariff.png'"
        :header="'Tarifas Atualizadas!'"
        :text="'Os dados das tarifas que submeteu foram atualizados com sucesso.'"
      />
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import mixins from "vue-typed-mixins";
import { General } from "../../mixins/general";
import TariffCycle from "@/components/dashboard/tariff-cycle.vue";

import FormTextbox from "@/components/forms/form-textbox.vue";
import FormSelect from "../forms/form-select.vue";
import FormRadio from "../forms/form-radio.vue";
import SubmitButton from "../dashboard/submit-button.vue";
import SuccessModal from "./success-modal.vue";
import FillTariffs from "../dashboard/fill-tariffs.vue";
import Vue from "vue";

export default mixins(General).extend({
  name: "EditModal",
  components: {
    FormTextbox,
    TariffCycle,
    FormSelect,
    FormRadio,
    SubmitButton,
    SuccessModal,
    FillTariffs,
  },
  props: ["modalName", "checkSubmit", "disableButton"],
  data() {
    return {
      isRotated: false,
      catSelected: 0,
      deletePeriods: false,
      editData: {} as any,
      valid: false,
      formErrors: [] as boolean[],
    };
  },

  mounted() {
    this.editData = JSON.parse(JSON.stringify(this.historic_data[0]));
  },
  computed: {
    numberTariffs: {
      get(): number {
        return this.$store.getters["numberEditTariffs"];
      },
      set(value: number) {
        this.$store.commit("setNumberEditTariffs", value);
      },
    },
    modalShow: {
      get(): string {
        return this.$store.getters["modalState"](this.modalName);
      },
      set() {
        //set back the value of periods to not mess with other components
        this.$store.commit("updatePeriodEdit", 0);
        this.closeModal(this.modalName);
      },
    },
  },
  methods: {
    reset() {
      const fillTariffsArray = this.$refs.fillTariffsEdit as any[];
      fillTariffsArray.forEach((tariffComponent, index) => {
        if (typeof tariffComponent.reset === "function") {
          tariffComponent.reset();
        } else {
          console.error(`Component ${index} does not have a validate method.`);
        }
      });
    },
    toggleRotation(index: any, status: boolean): any {
      this.$store.commit("updatePeriodEdit", index - 1);
      if (this.isRotated == true && this.catSelected == index) {
        this.isRotated = false;
      } else {
        this.catSelected = index;
        this.isRotated = status;
      }
    },
    callSubmitEvent(): void {
      this.$emit("callEvent");
    },

    incrementTariff(): void {
      this.$store.commit("setNumberEditTariffs", 1);
      this.$store.commit("addNewPeriodEdit");
    },

    removeTariff(index: number): void {
      this.$store.commit("removePeriodEdit", index);
      this.$store.commit("setNumberEditTariffs", -1);
      this.$store.dispatch("toggleSnackbar", {
        show: true,
        color: "green",
        message: `O Período ${index + 1} foi removido com sucesso!`,
      });
      this.deletePeriods = false;
    },

    decrementTariff(): void {
      if (this.deletePeriods == false) {
        this.deletePeriods = true;
      } else {
        this.deletePeriods = false;
        // this.$store.commit("removePeriod")
        // this.$store.commit("setNumberTariffs", -1)
      }
    },
    replaceEmptyStringsWithNull(obj: any) {
      for (const key in obj) {
        if (typeof obj[key] === "object" && obj[key] !== null) {
          this.replaceEmptyStringsWithNull(obj[key]);
        } else if (obj[key] === "") {
          this.$set(obj, key, 0); // Use Vue's $set to ensure reactivity
        }
      }
    },
    validate(btn_name: string) {
      const fillTariffsArray = this.$refs.fillTariffsEdit as any[];
      const isValid = (
        this.$refs.form as Vue & { validate: () => boolean }
      ).validate();
      fillTariffsArray.forEach((tariffComponent, index) => {
        if (typeof tariffComponent.validate === "function") {
          const isValided = tariffComponent.validate();
          if (isValided) {
            this.$set(this.formErrors, index, !isValided);
          } else {
            this.$set(this.formErrors, index, true);
          }
        } else {
          console.error(`Component ${index} does not have a validate method.`);
        }
      });
      if (isValid && this.formErrors.every(value => value === false)) {
        this.updateTariff(btn_name);
      } else {
        return false;
      }
    },
    updateTariff(btn_name: string): Promise<any> {
      let data = {
        uri: "bill_data",
        btn_name: btn_name,
      };

      data = { ...data, ...this.edit_data };
      this.replaceEmptyStringsWithNull(data);
      return this.$store.dispatch("sendPut", data).then(async (resp: any) => {
        await this.$store.dispatch("updateBillEditValue", resp);
        this.stopLoadingShowModal(btn_name, resp ? true : false);
        if (resp) {
          this.$store.dispatch("activateSnackBar");
        }
        if (this.condos_response.selected_role != "user" && resp != undefined) {
          await this.$store.dispatch("getContractData", {
            uri: "bill_data",
          });
        }
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.simple-content-modal {
  position: relative;
  min-width: 350px;
  background-color: rgba(107, 107, 107, 0.293);
}

.top-nav {
  width: 100%;
  height: 40px;
  font-size: 18px;
}

.sign {
  background-color: #47aea5;
  border-radius: 50%;
  width: 40px;
  height: 40px;

  .minus-sign {
    stroke: #fff;
  }

  .plus-sign {
    fill: red;
  }

  .plus-sign:disabled {
    color: #b1b1b1;
  }
}

@keyframes rotate90 {
  to {
    transform: rotate(90deg);
  }
}

@keyframes Nrotate90 {
  from {
    transform: rotate(90deg);
  }

  to {
    transform: rotate(0deg);
  }
}

@keyframes leaveShowDelete {
  from {
    transform: translateX(0px);
    opacity: 1;
  }

  to {
    transform: translateX(-56px);
    opacity: 0;
  }
}

@keyframes showDelete {
  from {
    transform: translateX(-56px);
    opacity: 0;
  }

  to {
    transform: translateX(0px);
    opacity: 1;
  }
}

.disabled-text {
  color: #b1b1b1 !important;
}

.revert {
  animation-name: Nrotate90;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
}

.rotated {
  animation-name: rotate90;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
}

.sign:disabled {
  background-color: #d8d8d8;

  .minus-sign {
    stroke: #b1b1b1;
  }
}

.delete-sign {
  display: flex;
  min-width: 56px;
  max-height: 56px;
  padding: 15px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 0px 10px 10px 0px;
  background: #ec5959;
}

.delete-sign {
  animation: showDelete 0.2s ease-in-out forwards;
}

.delete-sign.leave-active {
  animation: leaveShowDelete 0.2s ease-in-out forwards;
}

.tarif-button {
  width: 120px;
  height: 50px;
  background-color: #47aea5;
  color: white;
}
</style>
