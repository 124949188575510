<template>
  <v-row>
    <!-- Tab Pessoal -->
    <div class="col-12 px-0">
      <v-form ref="pessoalform" v-model="valid" lazy-validation>
        <v-col cols="12" class="pt-0 text text-5 normal-label">
          Nome completo
        </v-col>

        <v-col cols="12" class="pt-0 pb-0 px-3">
          <v-text-field
            :placeholder="profiles_dmp.length > 0 ? profiles_dmp[0].name : '-'"
            height="44px"
            background-color="#D8D8D8"
            class="filled-input"
            dense
            outlined
            disabled
          />
        </v-col>

        <div
          v-if="
            this.condos_response.selected_role == 'manager' &&
            this.condos_response.selected_profile
          "
        >
          <v-col cols="12" class="pt-0 text text-5 normal-label">
            Tipo de Representante do Condomínio
          </v-col>
          <v-col cols="12" class="pt-0 pb-0 px-3" v-if="profiles_dmp[0]">
            <v-text-field
              :placeholder="
                profiles_dmp[0].profile_type != ''
                  ? profiles_dmp[0].profile_type
                  : '-'
              "
              height="44px"
              background-color="#D8D8D8"
              class="filled-input"
              dense
              outlined
              disabled
            />
          </v-col>
        </div>
        <div v-if="this.condos_response.selected_role == 'user'">
          <div>
            <v-col cols="12" class="pt-0 text text-5 normal-label"> NIF </v-col>
            <v-col cols="12" class="pt-0 pb-0 px-3">
              <v-text-field
                :placeholder="
                  default_address_user
                    ? default_address_user.tax_identification_number
                    : '-'
                "
                height="44px"
                background-color="#D8D8D8"
                class="filled-input"
                dense
                outlined
                disabled
              />
            </v-col>
          </div>
          <div>
            <v-col cols="12" class="pt-0 text text-5 normal-label">
              Morada
            </v-col>
            <v-col cols="12" class="pt-0 pb-0 px-3">
              <v-text-field
                :placeholder="
                  default_address_user ? default_address_user.address : '-'
                "
                height="44px"
                background-color="#D8D8D8"
                class="filled-input"
                dense
                outlined
                disabled
              />
            </v-col>
          </div>

          <v-row class="px-3 pt-3">
            <v-col cols="6">
              <v-row>
                <v-col cols="12" class="pt-0 text text-5 normal-label">
                  Andar
                </v-col>
                <v-col cols="12" class="pt-0 pb-0 px-3">
                  <v-text-field
                    :placeholder="
                      default_address_user ? default_address_user.floor : '-'
                    "
                    height="44px"
                    background-color="#D8D8D8"
                    class="filled-input"
                    dense
                    outlined
                    disabled
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row>
                <v-col cols="12" class="pt-0 text text-5 normal-label">
                  Porta
                </v-col>
                <v-col cols="12" class="pt-0 pb-0 px-3">
                  <v-text-field
                    :placeholder="
                      default_address_user ? default_address_user.door : '-'
                    "
                    height="44px"
                    background-color="#D8D8D8"
                    class="filled-input"
                    dense
                    outlined
                    disabled
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="px-3 py-0">
            <v-col cols="6">
              <v-row>
                <v-col cols="12" class="pt-0 text text-5 normal-label">
                  Código postal
                </v-col>
                <v-col cols="12" class="pt-0 pb-0 px-3">
                  <v-text-field
                    :placeholder="
                      default_address_user
                        ? default_address_user.postal_code
                        : '-'
                    "
                    height="44px"
                    background-color="#D8D8D8"
                    class="filled-input"
                    dense
                    outlined
                    disabled
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row>
                <v-col cols="12" class="pt-0 text text-5 normal-label">
                  Cidade
                </v-col>
                <v-col cols="12" class="pt-0 pb-0 px-38">
                  <v-text-field
                    :placeholder="
                      default_address_user ? default_address_user.city : '-'
                    "
                    height="44px"
                    background-color="#D8D8D8"
                    class="filled-input"
                    dense
                    outlined
                    disabled
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>

        <v-col cols="12" class="pt-5 pb-3">
          <form-textbox
            :title="'Contacto telefónico'"
            :placeholder="'Contacto telefónico'"
            :profile_key="{
              index: this.condos_response.selected_profile,
              key: 'contact_phone',
            }"
            :title_alone="false"
            :bold="true"
            :typeNumber="true"
          />
        </v-col>

        <v-col cols="12" class="pt-2 pb-3"
          ><!-- aqui -->
          <form-textbox
            :title="'Email de contacto'"
            :placeholder="'Email de contacto'"
            :profile_key="{
              index: this.condos_response.selected_profile,
              key: 'contact_email',
            }"
            :title_alone="false"
            :bold="true"
            :rules="[emailRule]"
          />
        </v-col>
        <v-col
          cols="12"
          class="mb-5"
          v-if="this.condos_response.selected_role == 'user'"
        >
          <label class="switch">
            <input type="checkbox" v-model="usePersonalData" />
            <span class="slider round"></span>
          </label>
          <span class="pl-3"
            >Usar os detalhes do condomínio para efeitos de faturação</span
          >
        </v-col>
        <div
          v-if="
            usePersonalData &&
            this.condos_response.selected_role == 'user'
          "
          class="test-animation mb-5"
        >
          <v-col cols="12" class="pt-4 pb-4">
            <form-textbox
              :title="'Nome completo'"
              :placeholder="'Nome completo'"
              :profile_key="{
                index: this.condos_response.selected_profile,
                key: 'name',
                address_type: 'Billing Address',
                value: 'name',
              }"
              :title_alone="false"
              :bold="true"
              :rules="[requiredRule]"
            />
          </v-col>

          <v-col cols="12" class="pt-4 pb-4">
            <form-textbox
              :title="'NIF'"
              :placeholder="'000000000'"
              :profile_key="{
                index: this.condos_response.selected_profile,
                key: 'tax_identification_number',
                address_type: 'Billing Address',
              }"
              :title_alone="false"
              :bold="true"
              :rules="[requiredRule]"
              :typeNumber="true"
            />
          </v-col>

          <v-col cols="12" class="pt-4 pb-5">
            <form-textbox
              :title="'Morada'"
              :placeholder="'Rua'"
              :profile_key="{
                index: this.condos_response.selected_profile,
                key: 'address',
                address_type: 'Billing Address',
              }"
              :title_alone="false"
              :bold="true"
              :rules="[requiredRule]"
            />
          </v-col>
          <v-col cols="12" class="pt-4 pb-5">
            <form-textbox
              :title="'Fração'"
              :placeholder="'1 DTO'"
              :optional_field="'(Opcional)'"
              :profile_key="{
                index: this.condos_response.selected_profile,
                key: 'fraction',
                address_type: 'Billing Address',
              }"
              :title_alone="false"
              :bold="true"
            />
          </v-col>

          <v-row class="px-3 py-0">
            <v-col cols="6" class="pt-4 pb-2">
              <form-textbox
                :title="'Código Postal'"
                :placeholder="'0000-000'"
                :profile_key="{
                  index: this.condos_response.selected_profile,
                  key: 'postal_code',
                  address_type: 'Billing Address',
                }"
                :title_alone="false"
                :bold="true"
                :rules="[requiredRule]"
              />
            </v-col>
            <v-col cols="6" class="pt-4 pb-2">
              <form-textbox
                :title="'Cidade'"
                :placeholder="'Cidade'"
                :profile_key="{
                  index: this.condos_response.selected_profile,
                  key: 'city',
                  address_type: 'Billing Address',
                }"
                :title_alone="false"
                :bold="true"
                :rules="[requiredRule]"
              />
            </v-col>
          </v-row>
        </div>
        <v-col cols="12" v-if="this.condos_response.selected_role != 'user'"
          ><!-- aqui -->
          <submit-button
            :loading="loading"
            @callEvent="validate"
            :name="'handle-condos-profile'"
            :disabled="loading"
            :title="'Guardar'"
            :textSize="true"
            :height="35"
          />
        </v-col>
        <v-col cols="12" v-if="this.condos_response.selected_role == 'user'">
          <submit-button
            :textSize="true"
            @callEvent="validate"
            :name="'handle-user-profile'"
            :title="'Guardar'"
            :height="35"
          />
        </v-col>
      </v-form>
    </div>
  </v-row>
</template>

<script lang="ts">
import mixins from "vue-typed-mixins";
import { General } from "@/mixins/general";
import formTextbox from "@/components/forms/form-textbox.vue";
import SubmitButton from "@/components/dashboard/submit-button.vue";
import DashboardMode from "@/components/dashboard/dashboard-mode.vue";
import Vue from "vue";

export default mixins(General).extend({
  components: { formTextbox, SubmitButton, DashboardMode },
  name: "ManageAccount",
  data() {
    return {
      isChecked: false,
      usePersonalData: this.$store.state.doesUserHaveAdress,
      loading: false,
      hasError: false,
      valid: false,
      requiredRule: (v: any) => !!v || "Este campo é obrigatório",
    };
  },
  watch: {
    '$store.state.doesUserHaveAdress': {
      handler(newVal) {
        this.usePersonalData = !!newVal;
      },
    },
  },

  methods: {
    validate() {
      
      const isValid = (
        this.$refs.pessoalform as Vue & { validate: () => boolean }
      ).validate(); 
      if (isValid) {
        if (this.condos_response.selected_role == "user") {
          this.handleUserProfile();
        } else {
          this.handleCondoProfile();
        }
      }
    },
    emailRule(value: string): boolean | string {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      // If the value is empty, it's valid (not required)
      if (!value) {
        this.hasError = false;
        return true;
      }

      // Check if the email is in a valid format
      if (!emailPattern.test(value)) {
        this.hasError = true;
        return "Invalid email address";
      }

      this.hasError = false;
      return true;
    },
    //handles user data profile data
    handleUserProfile() {
      const partial = {
        contact_phone: this.profiles_dmp[0].contact_phone,
        contact_email: this.profiles_dmp[0].contact_email,
      };

      this.$store
        .dispatch("sendPartialUpdate", partial)
        .then(() => {
          this.$store.commit("toggleModalState", {
            status: true,
            name: "handle-user-profile",
          });
          this.$store.commit("toggleButtonState", {
            name: "handle-user-profile",
            status: false,
          });
        })
        .catch((e) => {
          this.$store.dispatch("toggleSnackBar", {
            show: true,
            color: "red",
            message: e.response.data.error,
          });
        });
      this.$store.commit("toggleButtonState", {
        name: "handle-user-profile",
        status: true,
      });
      if (
        this.condos_response.selected_role == "user" &&
        this.usePersonalData == false
      ) {
        this.$store
          .dispatch(
            "sendUserProfile",
            this.usePersonalData == false
              ? this.billing_address_user_length
              : this.default_address_user
          )
          .then(() => {
            this.loading = false;
            this.$store.commit("toggleModalState", {
              status: true,
              name: "handle-user-profile",
            });
            this.$store.commit("toggleButtonState", {
              name: "handle-user-profile",
              status: false,
            });
          })
          .then(() => {
            if (this.usePersonalData == true)
            this.$store.commit("resetBillingAddressUser");
            this.$store.dispatch("requestUser");
          });
      }
    },

    handleCondoProfile() {
      this.$store.commit("toggleButtonState", {
        status: true,
        name: "handle-condos-profile",
      });
      const partial = {
        contact_email: this.profiles_dmp[0].contact_email,
        contact_phone: this.profiles_dmp[0].contact_phone,
      };
      this.$store
        .dispatch("sendPartialUpdate", partial)
        .then(() => {
          this.$store.commit("toggleButtonState", {
            status: false,
            name: "handle-condos-profile",
          });
        })
        .then(() => {
          this.$store.commit("toggleModalState", {
            status: true,
            name: "handle-condos-profile",
          });
        })
        .catch((e) => {
          this.$store.dispatch("toggleSnackBar", {
            show: true,
            color: "red",
            message: e.response.data.error,
          });
        });
    },
  },

  computed: {
    profiles_dmp: function (): any {
      return this.$store.state.profiles.filter(
        (profile: any) => profile.id == this.condos_response.selected_profile
      );
    },
    default_address_user() {
      const selected_profile = this.$store.state.profiles
        ? this.$store.state.profiles.filter(
            (profile: any) =>
              profile.id == this.condos_response.selected_profile
          )[0]
        : null;
      return selected_profile
        ? selected_profile.addresses.filter(
          (address: any) => address.address_type == "Default Address"
          )[0]
        : null;
    },
  },
});
</script>

<style lang="scss"></style>
