<template>
  <v-container fluid fill-height class="py-0 login-page">
    <!-- Form area -->
    <v-row justify="space-around" class="full-height" align="center">
      <v-col class="login-form-area">
        <v-row>
          <v-col cols="12 text-center">
            <img
              class="login-logo"
              height="60"
              src="@/assets/logos/edge-white-logo.svg"
            />
          </v-col>
          <v-col cols="12 text-center">
            <v-card>
              <v-form ref="form">
                <v-row justify="space-around">
                  <v-col
                    :class="this.$route.name == 'Recover' ? 'pl-0' : 'pl-15'"
                    cols="10"
                    class="header header-3 text-center pt-6"
                  >
                    Alterar Password
                  </v-col>
                  <v-col
                    v-if="this.$route.name != 'Recover'"
                    cols="2"
                    class="d-flex justify-center align-center pr-7 pt-6 clickable"
                    @click="goBack()"
                    ><v-icon
                      class="d-flex align-center ml-auto mr-0"
                      @click="closeModal"
                      >mdi-close
                    </v-icon></v-col
                  >
                  <v-col cols="11" class="text-center pb-0">
                    <v-text-field
                      v-if="this.$route.name != 'Recover'"
                      outlined
                      dense
                      color="#41c5d3"
                      class="removeColorEye"
                      v-model="old_password"
                      placeholder="Inserir Password Atual"
                      :rules="[rules.required]"
                      :type="showPasswords.oldPassword ? 'text' : 'password'"
                      :append-icon="
                        showPasswords.oldPassword ? 'mdi-eye-off' : 'mdi-eye'
                      "
                      name="input-10-2"
                      @click:append="togglePasswordVisibility('oldPassword')"
                    >
                      <template v-slot:prepend-inner>
                        <v-icon class="icon login-icon"> mdi-lock </v-icon>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="11" class="text-center py-0 pb-1">
                    <v-text-field
                      outlined
                      dense
                      color="#41c5d3"
                      class="removeColorEye"
                      placeholder="Inserir Nova Password"
                      v-model="password"
                      :rules="[rules.required, rules.numeric, rules.minLength]"
                      :type="showPasswords.newPassword ? 'text' : 'password'"
                      :append-icon="
                        showPasswords.newPassword ? 'mdi-eye-off' : 'mdi-eye'
                      "
                      name="input-10-2"
                      @click:append="togglePasswordVisibility('newPassword')"
                    >
                      <template v-slot:prepend-inner>
                        <v-icon class="icon login-icon"> mdi-lock </v-icon>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="11" class="text-center py-0 pb-1">
                    <v-text-field
                      class="removeColorEye"
                      outlined
                      dense
                      color="#41c5d3"
                      placeholder="Confirmar Nova Password"
                      v-model="password_confirm"
                      :rules="[rules.required, rules.numeric, rules.minLength]"
                      validate-on="blur"
                      :type="
                        showPasswords.confirmPassword ? 'text' : 'password'
                      "
                      :append-icon="
                        showPasswords.confirmPassword
                          ? 'mdi-eye-off'
                          : 'mdi-eye'
                      "
                      name="input-10-2"
                      @click:append="
                        togglePasswordVisibility('confirmPassword')
                      "
                    >
                      <template v-slot:prepend-inner>
                        <v-icon class="icon login-icon"> mdi-lock </v-icon>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col
                    class="mb-1 px-7"
                    :cols="$route.name != 'Recover' ? 11 : 12"
                  >
                    <submit-button
                      @callEvent="validateForm"
                      depressed
                      dense
                      color="#47aea5"
                      class="login-button"
                      :loading="loading"
                      :title="'Mudar Password'"
                      :name="'change-password'"
                      :disabled="loading"
                    >
                    </submit-button>
                  </v-col>
                </v-row> </v-form
            ></v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- End form area -->
    <!-- Footer -->
    <v-row class="mb-0" justify="space-around">
      <!-- Footer text -->
      <v-col class="login-footer-text-area">
        <footer-text />
      </v-col>
      <!-- End footer text -->
    </v-row>
    <!-- End Footer -->

    <!-- Snackbar -->
    <snackbar />
  </v-container>
</template>

<script lang="ts">
import mixins from "vue-typed-mixins";
import { General } from "@/mixins/general";
import Snackbar from "@/components/general/snackbar.vue";
import FooterText from "@/components/general/footer-text.vue";
import submitButton from "@/components/dashboard/submit-button.vue";
import router from "@/router";

/* eslint-disable */
export default mixins(General).extend({
  name: "ChangePassword",
  components: {
    FooterText,
    Snackbar,
    submitButton,
  },
  data() {
    return {
      recoverPassword: false,
      email: "",
      password: "",
      password_confirm: "",
      showPasswords: {
        oldPassword: false,
        newPassword: false,
        confirmPassword: false,
      },
      old_password: "",
      loading: false,
      rules: {
        required: (value: any) =>
          !!value || this.error_message.required,
        minLength: (value: any) =>
          (!value || value.length >= 8) || this.error_message.minLenght,
        email: (value: any) =>
          !this.isEmailValid(value) ? this.error_message.invalid_email : true,
        numeric: (value: any) =>
          /[0-9]/.test(value) || this.error_message.missing_number,
      },
    };
  },

  created() {
    this.$store.commit("updateChangePassword", false);
    this.$store.commit("setRecoverToken", this.$route.params.token);
  },
  methods: {
    togglePasswordVisibility(
      field: "oldPassword" | "newPassword" | "confirmPassword"
    ) {
      this.showPasswords[field] = !this.showPasswords[field];
    },
    goBack() {
      window.history.back();
    },
    validateForm() {
      const form = this.$refs.form as any;

      if (form.validate()) {
        if (this.password !== this.password_confirm) {
          this.$store.dispatch("toggleSnackbar", {
            show: true,
            timeout: 5000,
            message: "As palavras-passe não coincidem.",
            color: "red",
          });
          return; // Stop further execution
        }
        this.submitForm();
      }
    },
    submitForm() {
      if (this.$route.name !== "Recover") {
        this.changePassword(
          this.old_password,
          this.password,
          this.password_confirm
        );
      } else {
        this.recoverPassword_(this.password, this.password_confirm);
      }
    },
    async recoverPassword_(pass: any, confirm: any): Promise<any> {
      const data = {
        new_password1: pass,
        new_password2: confirm,
        token: this.$route.params.token,
      };
      this.$store.commit("toggleButtonState", {
        state: true,
        name: "change_password",
      });
      this.$store
        .dispatch("requestRecoverPassword", data)
        .then(async (resp) => {
          await this.$store.commit("toggleButtonState", {
            state: false,
            name: "change_password",
          });
          return router.replace("/login").then(() => {
            this.$store.dispatch("toggleSnackbar", {
              show: true,
              timeout: 5000,
              message: "Password atualizada com sucesso!",
              color: "green",
            });
          });
        })
        .catch((e) => {
          return this.$store.dispatch("toggleSnackbar", {
            show: true,
            message: e.response.data.error,
          });
        });
    },

    async changePassword(
      old: string,
      pass: string,
      confirm: string
    ): Promise<void> {
      const data = {
        old_password: old,
        new_password: pass,
      };

      this.$store.commit("toggleButtonState", {
        state: true,
        name: "change_password",
      });

      // Check if passwords match before proceeding
      if (pass !== confirm) {
        this.$store.dispatch("toggleSnackbar", {
          show: true,
          message: "As palavras-passe não coincidem.", // Passwords do not match
          color: "red",
        });
        this.$store.commit("toggleButtonState", {
          state: false,
          name: "change_password",
        });
        return;
      }

      try {
        // Request to change password
        const response = await this.$store.dispatch(
          "requestChangePassword",
          data
        );

        if (response) {
          // Update the state to reflect the successful password change
          this.$store.commit("updateChangePassword", true);
          return this.$store.dispatch("logout").then(() => {
            this.$store.dispatch("toggleSnackbar", {
              show: true,
              timeout: 5000,
              message: "Password atualizada com sucesso!",
              color: "green",
            });
          });
        }
      } catch (error) {
        // Handle errors from the request
        const errorMessage =
          error.response?.data?.error ||
          "Ocorreu um erro ao alterar a password."; // Error message
        this.$store.dispatch("toggleSnackbar", {
          show: true,
          timeout: 5000,
          message: errorMessage,
          color: "red",
        });
      } finally {
        // Always toggle button state back to false
        this.$store.commit("toggleButtonState", {
          state: false,
          name: "change_password",
        });
      }
    },
  },
});
</script>

<style lang="scss">
#Layer_1:hover {
  fill: $blue-1;
}

.login-page {
  background-image: url("../assets/banners/login-banner.jpg");
  background-size: cover;
  object-fit: cover;
}

.login-form-area {
  max-width: 380px;
}

.recover-pass {
  color: $blue-1;
}

.login-logo {
  width: 50%;
  min-width: 150px;
}

.login-icon {
  margin-top: 3px;
  margin-bottom: 3px;
}

.login-footer-text-area {
  max-width: 380px !important;
}

.v-icon.v-icon {
  font-size: 22px !important;
}

.removeColorEye .v-icon {
  color: #757575 !important;
}
</style>
