import { render, staticRenderFns } from "./form-pessoal.vue?vue&type=template&id=35d50e1a"
import script from "./form-pessoal.vue?vue&type=script&lang=ts"
export * from "./form-pessoal.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports