<template>
  <v-row class="overlay-content" align="start" justify="center">
    <v-col @click="closeModal" class="d-flex justify-center options">
      <!-- Your content goes here -->
      <v-icon
        :ripple="false"
        style="padding-left: 280px"
        class="d-flex mt-3 mr-0"
        @click="closeModal"
        >mdi-close
      </v-icon>
    </v-col>
    <v-col class="d-flex justify-center options" cols="12">
      <img width="200" src="@/assets/logos/edge-white-logo.svg" />
    </v-col>
    <v-col cols="12" style="font-size: 14px" class="d-flex justify-center pb-0">
      Perfil Atual
    </v-col>
    <v-col
      cols="12"
      style="font-size: 24px"
      class="d-flex pt-0 pb-2 justify-center"
      >{{
        this.condos_response.selected_role == "user"
          ? "Morador"
          : "Administrador"
      }}</v-col
    >
    <v-col
      v-if="checkExistingUser"
      cols="12"
      class="d-flex align-center justify-center pt-0"
      ><button
        class="btn-change d-flex align-center justify-center"
        @click="handleChangeUser"
      >
        <img class="pr-1" src="@/assets/icons/change-user.svg" />
        <span>{{
          this.condos_response.selected_role == "manager"
            ? "Mudar Para Morador"
            : "Mudar Para Administrador"
        }}</span>
      </button></v-col
    >
    <v-col
      v-if="this.profiles.length != 0"
      style="font-size: 22px"
      cols="12"
      @click="handleChangeTab(0)"
    >
      <router-link
        :class="
          this.$route.path === '/dashboard/consumos' ? 'item-selected' : 'item'
        "
        class="d-flex clickable font-weight-bold justify-center"
        :to="{ path: '/dashboard/consumos' }"
      >
        Consumos
      </router-link>
    </v-col>
    <v-col
      v-if="
        this.condos_response &&
        this.profiles.length != 0 &&
        this.condos_response.selected_role == 'manager'
      "
      style="font-size: 22px"
      cols="12"
      @click="handleChangeTab(1)"
      class="d-flex clickable font-weight-bold item justify-center"
    >
      <router-link
        :class="
          this.$route.path === '/dashboard/tariff' ? 'item-selected' : 'item-1'
        "
        class="d-flex clickable font-weight-bold justify-center"
        :to="{ path: '/dashboard/tariff' }"
      >
        Faturação
      </router-link>
    </v-col>
    <!-- <v-col v-if="this.condos_response && this.condos_response.selected_role == 'manager'" style="font-size: 22px"
      cols="12" @click="handleChangeTab(2)" class="d-flex clickable font-weight-bold item justify-center">
      Relatórios
    </v-col> -->
    <v-col
      v-if="this.profiles.length != 0"
      style="font-size: 22px"
      cols="12"
      @click="handleSupport"
      class="d-flex clickable font-weight-bold item-1 justify-center"
    >
      <router-link
        :class="
          this.$route.path === '/dashboard/support' ? 'item-selected' : 'item-1'
        "
        class="d-flex clickable font-weight-bold justify-center"
        :to="{ path: '/dashboard/support' }"
      >
        Apoio ao Cliente
      </router-link>
    </v-col>

    <v-col
      v-if="this.profiles.length != 0"
      style="font-size: 22px"
      cols="12"
      @click="handleChangeTab(4)"
    >
      <router-link
        :class="
          this.$route.path === '/dashboard/manage-account'
            ? 'item-selected'
            : 'item-1'
        "
        class="d-flex clickable font-weight-bold justify-center"
        :to="{ path: '/dashboard/manage-account' }"
      >
        A minha conta
      </router-link>
    </v-col>

    <v-col
      style="font-size: 22px"
      cols="12"
      @click="logoutUser"
      class="d-flex clickable font-weight-bold item justify-center"
    >
      Logout / Sair
    </v-col>
  </v-row>
</template>

<script>
import router from "@/router";

import mixins from "vue-typed-mixins";
import { General } from "@/mixins/general";
export default mixins(General).extend({
  name: "MenuPage",

  computed: {
    checkExistingUser() {
      const selectedCondo = this.condos_response.selected_condo;

      const swapRole =
        this.condos_response.selected_role == "user" ? "manager" : "user";

      const result = this.profiles.filter(
        (item) =>
          (item.condominium_account
            ? item.condominium_account.id == selectedCondo
            : false) && this.translateRole(item.profile_type) == swapRole
      );
      if (result.length == 0) return false;
      return result;
    },
  },

  methods: {
    closeModal() {
      this.$emit("callEvent");
    },

    async handleChangeUser() {
      this.$store.commit("clearTimeoutId");
      await this.$store.dispatch("toggleSnackbarPastThirtyDays", {
        show: false,
        color: "noColor",
      });
      this.$store.commit(
        "setChargingStatesUpdate",
        !this.$store.state.update_charging_state
      );
      const selected_condo = localStorage.getItem("selected_condo");
      const selected_role = localStorage.getItem("selected_role");
      const selected_profile = localStorage.getItem("selected_profile");
      if (this.$route.path !== "/dashboard") {
        this.$router.push("/dashboard");
      }
      if (selected_role == "manager") {
        // Hide snack bars for the user
        this.$store.commit("updateState", { active_tab_profile: 1 });
        // Find profile with the user role in the same condominium
        const filteredProfiles = this.profiles.filter(
          (obj) =>
            obj.condominium_account.id == selected_condo &&
            (obj.profile_type == "Apartment Owner" ||
              obj.profile_type == "Apartment Tenant")
        );
        //There is no Faturação tab for users
        if (this.active_tab == 1) {
          this.$store.commit("updateState", { active_tab: 0 });
        }
        // Update the store with the new selected profile info
        if (filteredProfiles.length != 0) {
          +this.$store.commit("updateCondosInfo", {
            selected_role: "user",
            selected_profile: filteredProfiles[0].id,
            selected_condo: filteredProfiles[0].condominium_account.id,
          });
        }
        localStorage.setItem("selected_role", "user");
        localStorage.setItem("selected_profile", filteredProfiles[0].id);
        localStorage.setItem(
          "selected_condo",
          filteredProfiles[0].condominium_account.id
        );
        this.$store.dispatch("getBoxList");

        let result = await this.$store.dispatch("getBillingPeriods");
        if (this.billing_periods.length != 0)
          this.getConsumptionData(
            this.billing_periods[0].start_date,
            this.billing_periods[0].end_date
          );
        await this.$store.dispatch("getBillingPeriods").then(() => {
          if (this.billing_periods.length != 0)
            this.getConsumptionData(
              this.billing_periods[0].start_date,
              this.billing_periods[0].end_date
            );
        });
      } else {
        // Find profile with the manager role in the same condominium
        const filteredProfiles = this.profiles.filter(
          (obj) =>
            obj.condominium_account.id == selected_condo &&
            (obj.profile_type == "Condominium Manager" ||
              obj.profile_type == "Condominium Owner" ||
              obj.profile_type == "Condominium Administrator")
        );
        // Update the store with the new selected profile info
        if (filteredProfiles.length != 0) {
          this.$store.commit("updateCondosInfo", {
            selected_role: "manager",
            selected_profile: filteredProfiles[0].id,
            selected_condo: filteredProfiles[0].condominium_account.id,
          });
        }
        localStorage.setItem("selected_role", "manager");
        localStorage.setItem("selected_profile", filteredProfiles[0].id);
        localStorage.setItem(
          "selected_condo",
          filteredProfiles[0].condominium_account.id
        );

        this.$store.dispatch("getHubsList", {
          profile_id: localStorage.getItem("selected_profile"),
          profile_role: localStorage.getItem("selected_role"),
        });

        if (this.billing_periods.length != 0)
          this.getConsumptionData(
            this.billing_periods[0].start_date,
            this.billing_periods[0].end_date
          );

        await this.$store.dispatch("getBillingPeriods").then(() => {
          if (this.billing_periods.length != 0)
            this.getConsumptionData(
              this.billing_periods[0].start_date,
              this.billing_periods[0].end_date
            );
        });
      }
      this.closeModal();
    },

    handleChangeTab(tab_index) {
      // If selected tab is Consumos, update consumption data
      if (tab_index == 0) {
        // Update consumption data in the store for the last period if any
        if (this.billing_periods.length > 0) {
          this.getConsumptionData(
            this.billing_periods[0].start_date,
            this.billing_periods[0].end_date
          );
        }
        // Update consumption data in the store for the previous period if any
        if (this.billing_periods.length > 1) {
          this.getConsumptionData(
            this.billing_periods[1].start_date,
            this.billing_periods[1].end_date,
            true
          );
        }
      }
      this.closeModal();
      this.$store.commit("updateState", { active_tab: tab_index });
    },
    handleSupport() {
      this.closeModal();
      this.$store.commit("updateState", { active_tab: 3 });
    },

    logoutUser() {
      return this.$store.dispatch("logout");
    },
  },
});
</script>

<style scoped lang="scss">
.btn-change {
  border: 1px solid #fff;
  color: #fff;
  width: 210px;
  height: 40px;
  border-radius: 5px;
}

.btn-change:hover {
  border: 1px solid $blue-1;
  background-color: $blue-1;
  transition: 0.5s;
}

.bg-menu {
  background-color: rgba(0, 0, 0, 0.85);
  width: 100vw;
  height: 100%;
  align-items: start;
  justify-content: start;
}

.item:hover {
  color: #b1b1b1 !important;
  transition: 0.5s;
}

.item,
.item-1 {
  color: #fff;
  font-weight: 400 !important;
}

.item-selected {
  color: $blue-1 !important;
  transition: 0.5s;
}

.item-1:hover {
  color: $blue-1 !important;
  transition: 0.5s;
}

.options {
  margin-top: 70px;
}

@media (max-width: 600px) {
  .options {
    margin-top: 30px;
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.5s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(-100%);
}

.overlay-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100vw;
  transition: transform 0.3s;
}

.v-navigation-drawer--is-mouseover {
  box-shadow: none !important;
  /* Add any other style overrides here */
}

.theme--dark.v-icon:focus::after {
  opacity: 0;
}

.v-overlay__scrim {
  display: none !important;
}
</style>
